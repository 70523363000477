@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
  scroll-padding-top: 3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

/*custom properties*/
:root {
  --white-color: #fff;
  --black-color: rgb(2, 2, 2);
  --blue-color: rgb(17, 10, 44);
  --orange-color: rgb(175, 90, 10);
  --lighter-color: #f0ece6;

  --largefont-size: 2.3rem;
  --medfont-size: 1.8rem;
  --hfont-size: 1.5rem;
  --minifont-size: 1.2rem;
  --bodyfont-size: 1rem;
}

body {
  background: var(--white-color);
  color: var(--black-color);
  width: 100%;
}

/*Header beginning*/
header {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  background: #191919;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
}

.cart-bag {
  display: inline-flex;
  color: #ccb160;
  width: 40px;
  margin-left: 15px;
}

.logo {
  width: auto;
  height: 33px;
}

.bag-quantity {
  display: block;
  height: 20px;
  width: 20px;
  background: #8a6252;
  font-size: 12px;
  color: white;
  margin-left: 3px;
  font-weight: bold;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 5px;
  padding: 2px;
}

.loginlogout {
  display: flex;
  color: #f0ece6;
  flex-direction: column;
}

.login {
  color: white;
}

.logout {
  color: #ccb160;
}

.register {
  color: #ccb160;
}

.not-found {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  color: #3d3c3c;
  align-items: center;
}

.not-found-head {
  display: inline-flex;
  color: #313131;
  padding-top: 120px;
  padding-bottom: 20px;
  font-size: 100px;
  font-weight: 700;
}

.not-found-text {
  display: inline-flex;
  color: #3d3c3c;
  font-size: var(--medfont-size);
  font-weight: 400;
}

/*Home*/
.error-home {
  margin-top: 20px;
  font-size: 20px;
}

.home {
  padding-left: 18px;
  padding-right: 18px;
}

.home-heading {
  margin-top: 8px;
  display: block;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.1rem;
  border-radius: 5px;
  width: 157px;
  height: auto;
  margin-top: 15px;
  margin-bottom: 5px;
}

.product-name {
  margin-top: 2px;
  font-size: 15px;
  font-weight: 600;
  width: 153px;
  display: flex;
  flex-wrap: wrap;
}

.product img {
  display: flex;
  justify-content: center;
  height: 240px; /* Set a fixed height */
  width: 153px;
  object-fit: cover; /* Ensure the image covers the container */
  object-position: top;
  overflow: hidden; /* Hide any overflow */
  border-radius: 3px;
  cursor: pointer;
}

.details {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
}
.product .price {
  margin-top: 3px;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 3px;
}

.product button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 40px;
  border-radius: 16px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #8a6252;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 10px;
}

.product button:hover {
  background: #ccb160;
}

.view-dress-div {
  border: 1px solid #ccb160;
  border-radius: 16px;
  width: 450px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-dress-div img {
  width: auto;
  height: 425px;
  border-radius: 16px;
}

.add-div {
  display: flex;
  height: 70px;
  margin-left: 24px;
  margin-top: 24px;
}

.view-dress-add {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 200px;
  height: 55px;
  background-color: #8a6252e5;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
}

.view-dress-add:hover {
  background: #ccb160;
}

.back-to-home-div {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 20px;
}

.back-to-home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  font-size: 14px;
  color: #fff;
  background: #090b0f;
  cursor: pointer;
}

/* Cart */
.cart-container {
  padding: 24px;
}

.cart-login {
  margin-top: 14px;
}

.cart-container h2 {
  font-weight: 700;
  font-size: 25px;
  text-align: center;
}
.cart-container .titles {
  margin-top: 30px;
}

.cart-container .titles h3 {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.cart-item,
.cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}

.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 1rem;
}

.cart-item .cart-product {
  display: flex;
}

.cart-item .cart-product img {
  width: 70px;
  height: 80px;
  margin-right: 5px;
  margin-top: 10px;
}

.product-desc {
  width: 50px;
}

.cart-item .cart-product h3 {
  font-size: 15px;
  font-weight: 700;
}

.cart-item .cart-product p {
  font-size: 13px;
  font-weight: 400;
}
.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: gray;
}
.cart-item .cart-product button:hover {
  color: black;
}

.cart-item .cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 60px;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}
.cart-item .cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 0.6rem;
  cursor: pointer;
}
.cart-item .cart-product-quantity .count {
  padding: 0.7rem 0;
}
.cart-item .cart-product-total-price {
  font-weight: 700;
}

/* cart summary */
.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
}
.cart-summary .clear-btn {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}
.cart-checkout {
  width: 270px;
  max-width: 100%;
}
.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.cart-checkout .amount {
  font-weight: 700;
}
.cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #ccb160;
  color: black;
  border: none;
  outline: none;
  cursor: pointer;
}
.continue-shopping,
.start-shopping {
  margin-top: 1rem;
}
.continue-shopping a,
.start-shopping a {
  color: gray;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.continue-shopping a span,
.start-shopping a span {
  margin-left: 0.5rem;
}
.cart-empty {
  font-size: 20px;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.register-head {
  display: block;
  text-align: center;
  margin-top: 20px;
  font-size: var(--minifont-size);
  font-weight: 700;
  margin-bottom: 10px;
}

input {
  display: block;
  margin-bottom: 5px;
  margin-left: 10%;
  width: 80%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #000;
}

.register-button {
  margin-left: 10%;
  width: 80%;
  height: 40px;
  border-radius: 5px;
  background: #ccb160;
  border: 1px solid #000;
  color: black;
}

.login-head {
  display: block;
  text-align: center;
  margin-top: 20px;
  font-size: var(--minifont-size);
  font-weight: 700;
  margin-bottom: 10px;
}

.login-button {
  margin-left: 10%;
  width: 80%;
  height: 40px;
  border-radius: 5px;
  background: #ccb160;
  border: 1px solid #000;
  color: black;
}

/*
Admin
*/

/*dashboard*/
.welcome-container {
  text-align: center;
  padding: 20px;
}

.welcome-message {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.instruction {
  font-size: 18px;
  margin-bottom: 20px;
}

.logout-button {
  margin-top: 36px;
  padding: 10px 20px;
  background-color: #8a6252;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f;
}

/*Admin Login page*/
.loginpage-header {
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 10px;
}

.admin-login-btn {
  padding-left: 45px;
  padding-right: 45px;
  margin-left: 30%;
  margin-top: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: white;
  border-radius: 20px;
  border: 2px solid #8a6252;
  background: #8a6252;
}

/*Dashboard*/
.admin {
  display: block;
  width: 100%;
}

.quick-links {
  display: block;
}

.navlink {
  width: 100%;
  display: block;
  background: #8a6252;
}

.dashboard {
  display: block;
  text-align: center;
  color: white;
  font-size: 25px;
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: bold;
}

.dash-menu {
  display: flex;
  width: 100%;
  background: #8a6252;
  justify-content: space-evenly;
  padding-bottom: 12px;
}
.dashboard-list {
  color: black;
  font-weight: bold;
  font-size: 15px;
  background: #ccb160;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

/*Admin products*/
.products-main {
  display: block;
  width: 100%;
  width: 100%;
  flex-wrap: wrap;
}

.addproduct-btn {
  display: block;
  margin-top: 20px;
  margin-left: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  border: 2px solid black;
  background: black;
  color: white;
}

.all-products {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 30px;
}

.product-price {
  width: 70px;
  margin-left: 5px;
  font-size: 17px;
  font-weight: bold;
}

.products-btn {
  height: 30px;
  margin-left: 5px;
  color: black;
  font-weight: bold;
  font-size: 12px;
  background: #ccb160;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.add-product-section form div {
  text-align: center;
  margin-top: 10px;
}

.add-product-section div img {
  width: 250px;
  height: 250px;
  margin-left: 50px;
}

.add-product-section form button {
  color: white;
  border-radius: 20px;
  border: 2px solid #8a6252;
  background: #8a6252;
  width: 100px;
  margin-left: 32%;
  padding: 5px;
  margin-top: 10px;
}

.customer-info {
  display: block;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}
.user-div {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 20px;
}

.login-tag {
  display: block;
  text-align: center;
  margin-top: 5px;
}

.details-display {
  display: block;
  text-align: center;
}

.user-login-btn {
  color: white;
  border-radius: 20px;
  border: 2px solid #8a6252;
  background: #8a6252;
  width: 100px;
  margin-left: 32%;
  padding: 5px;
  margin-top: 10px;
}

.register-link {
  margin-top: 10px;
  text-align: center;
}

.different-error {
  margin-top: 5px;
  text-align: center;
}

/*
Loader
*/
.loading-overlay {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #ccb160;
  border-top: 16px solid #8a6252;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*admin user*/
.users-container {
  max-width: 360px;
  margin: 0 auto;
  padding: 20px;
}

.customer-info {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.no-users {
  margin-bottom: 10px;
}

.user-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background: #8a6252;
}

.user-info {
  color: white;
  flex-basis: 100%;
  margin-bottom: 10px;
  font-weight: bold;
}

.user-info:last-child {
  margin-bottom: 0;
}

.whatsapp-logo {
  width: 46px;
  height: 46px;
}

.whatsapp-link {
  width: 46px;
  background: none;
  position: fixed;
  right: 0;
  top: 320px;
}

.search-div {
  width: 30px;
  height: 30px;
  margin-bottom: 4px;
}

.won {
  width: 22px;
  height: 22px;
  color: #ccb160;
  margin-left: 6px;
  margin-top: 5px;
}

.search-area {
  position: absolute;
  display: block;
  top: 46px;
}

.search-area-div {
  display: flex;
}

.search-area-div input {
  height: 34px;
  width: 250px;
  border: 3px solid #ccb160;
  border-radius: 20px;
}

.search-area-div button {
  position: absolute;
  display: block;
  top: 30px;
  border: white;
  background: none;
  color: #8a6252;
  font-size: 22px;
  margin-left: 220px;
}

#uk-flag {
  display: none;
}

.product button {
  width: 100%;
  font-size: 14px;
}

.super-view-dress {
  display: flex;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 50px;
}

@media (max-width: 600px) {
  #search-input {
    padding-left: 20px;
    height: 43px;
    width: 202.25px;
  }
  .search-area-btn {
    height: 30px;
    width: 30px;
    margin-left: -33px;
  }
  .search-area-div {
    width: 225px;
  }
  .product {
    width: 48%;
  }
  .product img {
    border-radius: 16px;
    width: 100%;
    height: 186px;
  }
  .product button {
    width: 120px;
    font-size: 12px;
  }
  .super-view-dress {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-dress-div {
    width: 80%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-dress-div img {
    width: auto;
    height: 270px;
  }
  .right-view-dress {
    margin-top: 20px;
  }

  .view-dress-add {
    margin-top: -20px;
  }
}

@media (min-width: 700px) {
  header {
    width: 100%;
    height: 120px;
    padding-left: 100px;
    padding-right: 100px;
    background: #191919;
  }
  .home {
    padding-left: 100px;
    padding-right: 100px;
  }
  .logo {
    width: auto;
    height: 100px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }
  .search-area {
    position: static;
  }
  #search-input {
    padding-left: 20px;
    height: 43px;
    border: none;
  }

  #search-but {
    margin-top: 12px;
  }
  .search-area-btn {
    height: 30px;
    width: 30px;
    margin-left: -5px;
  }
  #uk-flag {
    display: flex;
    width: 30px;
    height: 20px;
  }

  .link-cart-bag {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product {
    width: 225px;
  }

  .product img {
    width: 214px;
    height: 214px;
    border-radius: 16px;
  }

  .cart-container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .cart-product {
    width: 400px;
  }
  #sp-cart-img {
    width: 150px;
    height: 112px;
    border-radius: 16px;
    margin-top: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    object-fit: cover;
    object-position: top;
  }
  .product-desc {
    width: 400px;
  }
  .first-timers {
    color: #fff;
    font-weight: 600;
  }
  .cart-bag {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bag-quantity {
    width: 25px;
    height: 25px;
  }
  .product button {
    width: 70%;
    font-size: 14px;
  }
  .reg-div input,
  .reg-div .login-tag {
    width: 400px;
  }

  .reg-div input {
    padding-left: 20px;
    margin-left: 0;
  }

  .auth-forms {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .auth-forms button {
    margin-right: 25%;
  }

  .log-forms {
    margin-left: 400px;
    margin-right: 400px;
  }
}
